import React, { createContext, useCallback, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Route, Routes, useNavigate } from 'react-router';
import './App.scss';
import './keyframes.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { AboutUs, ContactUs, Gallery, Home, PrivacyPolicy, Terms } from './Pages';
import { AuthState } from './common/types';
import { getUser } from './common/UserUtils';
import 'font-awesome/css/font-awesome.min.css';

const routes = [
	'/',
	'/gallery',
	'/about-us',
	'/contact-us',
	'/privacy-policy',
	'/terms',
];

export const UserContext = createContext<AuthState | undefined>(undefined);

function App() {
	const [authState, setAuthState] = useState<AuthState | undefined>(undefined);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [previousY, setPreviousY] = useState(0);

	const navigate = useNavigate();

	useEffect(() => {
		setAuthState(getUser());
	}, []);

	useEffect(() => {
		if(!routes.includes(window.location.pathname)){
			navigate('/');
		}
	}, [navigate]);

	const toggleDrawerOpen = useCallback(() => {
		setPreviousY(window.scrollY);
		setDrawerOpen(!drawerOpen);

		if(drawerOpen) {
			//closing
			document.querySelector('#root')?.classList.remove('modal-open');
			document.querySelector('html')?.classList.add('auto');
			window.scrollTo(0, previousY);
			document.querySelector('html')?.classList.remove('auto');
		}

		else {
			//opening
			document.querySelector('#root')?.classList.add('modal-open');
		}
		
	}, [drawerOpen, previousY]);

	return (
		<div className={`App`}>
			<header className='app-header'>
				<div className="header-container">
					<a href="/" className='logo'><img src="/Media/Icons/logo-transparent.png" alt="logo" /></a>
					<div className="link-container">
						<a href="/">Home</a>
						<div className="divider"></div>
						<a href="/gallery">Gallery</a>
						<div className="divider"></div>
						<a href="/about-us">About Us</a>
						<div className="divider"></div>
						<a href="/contact-us">Contact Us</a>
						<a href="https://booking.nesthomemy.com" className='app-button'>Book Now</a>
					</div>
				</div>
			</header>

			<button className={`drawer-button ${drawerOpen? 'active' : ''}`} onClick={toggleDrawerOpen}>
				<i className={`fa fa-${drawerOpen? 'times' : 'bars'}`}></i>
			</button>

			<div className={`mobile-drawer ${drawerOpen? 'active' : ''}`}>
				<div className="link-container">
					<a href="/" className={window.location.pathname === "/"? 'active' : ''}>Home</a>
					<a href="/gallery" className={window.location.pathname === "/gallery"? 'active' : ''}>Gallery</a>
					<a href="/about-us" className={window.location.pathname === "/about-us"? 'active' : ''}>About Us</a>
					<a href="/contact-us" className={window.location.pathname === "/contact-us"? 'active' : ''}>Contact Us</a>
					<a href="https://booking.nesthomemy.com" className='app-button'>Book Now</a>
				</div>
			</div>

			<UserContext.Provider value={authState}>
				{/** Please update routes constant if there's a new page */}
				<Routes>
					<Route path="/" element={<Home />}></Route>
					<Route path="/about-us" element={<AboutUs />}></Route>
					<Route path="/contact-us" element={<ContactUs />}></Route>
					<Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
					<Route path="/terms" element={<Terms />}></Route>
					<Route path="/gallery" element={<Gallery />}></Route>
				</Routes>
			</UserContext.Provider>

			<ToastContainer
                position="bottom-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme={'colored'}
            />
			<footer>
				<div className="footer-container">
					<div className="company-details">
						<div>{process.env.REACT_APP_COMPANY_NAME} ({process.env.REACT_APP_COMPANY_REG_ID})</div>
						<div>{process.env.REACT_APP_COMPANY_ADDRESS}</div>
						<div className='mt-5'>Tel: {process.env.REACT_APP_COMPANY_TEL}</div>
						<div>Email: <a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`}>{process.env.REACT_APP_COMPANY_EMAIL}</a></div>

						<div className='mt-5'>
							Connect with us
                            <ul className="social-icons">
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/nesthomemy" title="Facebook" className='facebook'><i className="fa fa-facebook"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/nesthome.my" title="Instagram" className='instagram'><i className="fa fa-instagram"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://wa.me/60176228191?text=Hi, I would like to know more about your services!" title="Whatsapp" className='whatsapp'><i className="fa fa-whatsapp"></i></a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="https://www.airbnb.com/users/351932154/listings" title="Airbnb" className='airbnb'><i className="fa fa-airbnb"></i></a></li>
                            </ul>   
						</div>
					</div>
					<div className="site-map">
						<strong>Sitemap</strong>
						<ul className='webpages'>
							<li><a href="/">Home</a></li>
							<li><a href="/gallery">Gallery</a></li>
							<li><a href="/about-us">About Us</a></li>
							<li><a href="/contact-us">Contact Us</a></li>
							<li><a href="/privacy-policy">Privacy Policy</a></li>
							<li><a href="/terms">Terms</a></li>
							<li><a href="https://booking.nesthomemy.com">Booking</a></li>
						</ul>
					</div>
				</div>
				<div className="footer-links">
					<div className="links-container">
						<a href="/privacy-policy">Privacy Policy</a>
						<a href="/terms">Terms</a>
					</div>
					<span>Copyright &copy; 2022 {process.env.REACT_APP_COMPANY_NAME} All Rights Reserved</span>
				</div>
			</footer>
		</div>
	);
}

export default App;
