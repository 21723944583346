import React, { Component } from 'react'
import './styles.scss'

import GoogleMapReact from 'google-map-react';

const MapMarker = ({ text }: { text: string; lat: number; lng: number }) => (<div><i style={{ fontSize: '28px', color: 'red' }} className="fa fa-map-marker"></i>{text}</div>)

interface P {

}

interface S {
    center: {
        lat: number;
        lng: number;
    };
    zoom: number;
}

export class ContactUs extends Component<P,S> {
    // no props
    constructor(props: P) {
        super(props)

        this.state = {
            center: {
                lat: parseFloat(process.env.REACT_APP_COMPANY_LAT!),
                lng: parseFloat(process.env.REACT_APP_COMPANY_LNG!)
              },
            zoom: 17
        }
    }

    render() {
        const { center, zoom, } = this.state
        
        console.log({center})
        return (
            <div className="contact-us">
                <div className="spotlight accent2">
                    <div className="inner">
                        
                        <div className="content">
                            <h3>Get In Touch For More Details</h3>
                            <p>If you have any questions or concerns, please do let us know via contact info below: </p>
                            <div className="contact-address">
                                <div className="contact-label">Company Name.</div><div className="contact-details">{process.env.REACT_APP_COMPANY_NAME} ({process.env.REACT_APP_COMPANY_REG_ID})</div>
                                <div className="contact-label">Address.</div><div className="contact-details">{process.env.REACT_APP_COMPANY_ADDRESS}</div>
                                <div className="contact-label">Phone No.</div><div className="contact-details">{process.env.REACT_APP_COMPANY_TEL}</div>
                                <div className="contact-label">Email.</div><div className="contact-details"><a href={`mailto:${process.env.REACT_APP_COMPANY_EMAIL}`}>{process.env.REACT_APP_COMPANY_EMAIL}</a></div>
                            </div>
                            <div className="contact-social">
                                <p className="contact-social-label">Connect With Us</p>
                                <ul className="contact-social-icons">
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/nesthomemy" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/nesthome.my" title="Instagram"><i className="fa fa-instagram"></i></a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://wa.me/60176228191?text=Hi, I would like to know more about your services!" title="Whatsapp"><i className="fa fa-whatsapp"></i></a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.airbnb.com/users/351932154/listings" title="Airbnb"><i className="fa fa-airbnb"></i></a></li>
                                </ul>    
                            </div>

                        </div>
                        <div className="cls-image">
                            <img src="/Media/contact/contact-img-001.jpg" alt="contact-us" className='contact-us-img'/>
                        </div>
                    </div>
                </div>

                <h2>Where to Find Us</h2>
                <div className='mb-5' style={{ height: '50vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY! }}
                        center={ center }
                        zoom={ zoom }
                        onClick={() => window.open("https://www.google.com/maps/place/Cube8teens+by+Nest+Home/@1.5692411,103.7738474,17z/data=!3m1!4b1!4m8!3m7!1s0x31da6fba8d92cc65:0x5f6500485e70acc1!5m2!4m1!1i2!8m2!3d1.5692357!4d103.7760361")}
                    >     
                        <MapMarker
                            lat={this.state.center.lat}
                            lng={this.state.center.lng}
                            text="We're Here"
                        />                   
                    </GoogleMapReact>
                </div>

            </div>
        )
    }
}

export default ContactUs;