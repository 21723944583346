import axios from '../Services/axios';
import { AuthState, APIResponse, Reservation } from "./types";
import { AxiosResponse } from "axios";

/**
 * Sign in with Google using GoogleSignIn's returned idToken
 * @param string token 
 */
 export const signInWithGoogle = async ( token: string ) => {

    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/guest/signIn", {
            token, 
            type: '0'
        });
    
        if (!response.data.success) {
            return false;
        }
        
        return response.data.data;
    }

    catch {
        return false;
    }
}

/**
 * Sign in with Facebook using FacebookSignIn's returned access token
 * @param string accessToken 
 */
export const signInWithFacebook = async ( token: string ) => {
    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/guest/signIn", {
            token, 
            type: '1'
        });
    
        if (!response.data.success) {
            return false;
        }

        return response.data.data;
    }

    catch {
        return false;
    }

}

/**
 * Sign in with registered email and password
 * @param string email 
 * @param string password 
 */
export const signInWithEmail = async ( email: string, password: string ) => {
    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/guest/signIn", {
            "email": email,
            "password": password,
            "type": "2"
        });
        
        if (!response.data.success) {
            return false;
        }

        return response.data.data;
    }

    catch {
        return false;
    }
}

/**
 * Sign up using email and password
 * @param firstName 
 * @param lastName 
 * @param email 
 * @param password 
 */
export const signUpWithEmail = async ( firstName: string, lastName: string, email: string, password: string ) => {
    try {
        const response = await axios.post<any, AxiosResponse<APIResponse<AuthState>>>("/guest/signUp", {
            email,
            password,
            first_name: firstName,
            last_name: lastName,
        });
    
        if (!response.data.success) {
            return false;
        }
        return response.data.data;
    }

    catch {
        return false;
    }
}

export const setPhoneNum = async( phoneNum: string ) => {
    let user = getUser();
    if(!user){
        alert( 'Session expired, please log in again!' )
        return;
    }

    let { token } = user;

    if ( !token ){
        alert( 'Session expired, please log in again!' )
        return
    }

    const url = `/guest/set_phone_num`
    return axios.post(
        url,
        {
            phone_num: phoneNum,
        }
    )
}

export const getPhoneNum = async() => {
    let user = getUser();
    if(!user){
        alert( 'Session expired, please log in again!' )
        return;
    }

    let { token } = user;

    if ( !token ){
        alert( 'Session expired, please log in again!' )
        return
    }

    const url = `/guest/get_phone_num`
    return axios.get(url);
}

/**
 * Returns true if password changed successfully
 * @param newPassword 
 * @param oldPassword 
 */
export const changePassword = async( newPassword: string, oldPassword: string ) => {
    let user = getUser();
    if(!user){
        alert( 'Session expired, please log in again!' )
        return;
    }

    let { token } = user;

    if ( !token ){
        alert( 'Session expired, please log in again!' )
        return
    }

    const url = `/guest/change_password`
    return axios.post(
        url,
        {
            new_password: newPassword,
            old_password: oldPassword,
        }
    )
}

/**
 * Returns name from token. For token validation purposes.
 * @param token 
 */
export const getUserFromId = async( id: number ) => {
    const url = `/guest/info`;
    return axios.get<any, AxiosResponse<APIResponse<AuthState>>>(url, { data: { id } });
}

//todo
/**
 * Returns current user reservations
 * @param token 
 */
export const getReservations = async() => {
    const url = `/guest/reservations`;
    return await axios.get<any, AxiosResponse<APIResponse<Reservation[]>>>(url);
}

/**
 * Gets user token obtained previously
 */
 export const getUser = (): AuthState | undefined  => {
    let userStr = localStorage.getItem( 'user' );

    if ( !userStr || userStr === 'undefined' ){
        return undefined;
    }

    try {
        let user = JSON.parse( userStr );
        let { token } = user;
    
        if ( !token ){
            return undefined;
        }
        
        return user;
    }

    catch {
        clearUser();
        return undefined;
    }
}

export const isUserValid = async() => {
    let userStr = localStorage.getItem( 'user' )

    if ( !userStr ){
        return false
    }

    let user: AuthState = JSON.parse( userStr )
    let { userId } = user

    if ( !userId ){
        return false
    }

    let { data } = await getUserFromId( userId )

    if(data.success){
        clearUser();
        setUser(data.data);
    }

    return data.success;
}

/**
 * Sets user token obtained from wp.nesthomemy.com
 * @param token 
 */
export const setUser = ( user: AuthState ) => {
    return localStorage.setItem( 'user', JSON.stringify( user ) )
}

/**
 * Clears previously stored token. To be used in log out.
 */
export const clearUser = () => {
    return localStorage.removeItem('user');
}