import React from 'react';
import './styles.scss';

const AboutUs = () => {
    return (
        <div className='about-us'>
            <div className="section align-items-start">
                <img src="/Media/about-us.png" alt="about-us" className='about-us-img'/>
                <img src="/Media/about-us-mobile.png" alt="about-us" className='about-us-mobile-img'/>
            </div>
            <div className="section text">
                <h1>We Are Nest Home, Malaysia's Up and Coming Property Management Company</h1>
                <p>Established in 2019, our team has grown from 3 to 5 to 30 in just 3 years. The number of properties under our management have also grown from under 10 to more than 100! During the pandemic, we had encountered a lot of hardships where the tourism in Malaysia, both locally and overseas, was heavily impacted. Yet, despite that, we conquered all the quandaries and now, we thrive in the revived market!</p>
            </div>
            <div className="dark section text">
                <h2>Combined, we have over 10 years experience in property management.</h2>
                <p>Our experiences range from the managing maintenance and cleaning teams, to designing and renovating properties, providing a seamless experience to all our current and would-be patrons. We also have an in house IT team to improve the overall experience of our patrons by providing a website and an app for them to view their properties' performances at any given time.</p>
            </div>
            <div className="big section flex-column">
                <h2>Our Services</h2>
                <div className="image-container">
                    <div className="sub-image-container">
                        <img src="/Media/homeowner/interior_design.jpg" alt="interior_design" />
                        <span>Interior Design</span>
                    </div>
                    <div className="sub-image-container">
                        <img src="/Media/homeowner/professional_photography.jpg" alt="professional_photography" />
                        <span>Professional Photography</span>
                    </div>
                    <div className="sub-image-container">
                        <img src="/Media/homeowner/property_management.jpg" alt="property_management" />
                        <span>Property Management</span>
                    </div>
                </div>
            </div>
            <div className="dark big section flex-column">
                <h2>We Are Operating In</h2>
                <div className="location-container">
                    <div className="location shadow">
                        <span>Medini</span>
                    </div>
                    <div className="location shadow">
                        <span>Johor Bahru</span>
                    </div>
                    <div className="location shadow">
                        <span>Bukit Jalil</span>
                    </div>
                    <div className="location shadow">
                        <span>Kuala Lumpur</span>
                    </div>
                    <div className="location shadow">
                        <span>Danga Bay</span>
                    </div>
                    <div className="location shadow">
                        <span>Plentong</span>
                    </div>
                    <div className="location shadow">
                        <span>Kluang</span>
                    </div>
                    <div className="location shadow">
                        <span>South Key</span>
                    </div>
                </div>
            </div>
            <div className="section flex-column">
                <h2 className='mb-5'>Be One Of Us</h2>
                <a href="/contact-us" className='btn btn-lg text-uppercase'>
                    Contact Us Now
                    <i className="fa fa-chevron-right ml-3"></i>
                </a>
            </div>
        </div>
    )
}

export default AboutUs;