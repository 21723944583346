import axios from 'axios';
import { getUser } from '../common/UserUtils';
export const BASE_URL = window.location.href.includes('nh.test') || window.location.href.includes('localhost')? "http://org.nh.test/api/f" : "https://org.nesthomemy.com/api/f";

let instance = axios.create({
    baseURL: BASE_URL,
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
    },
    responseType: 'json',
});

instance.interceptors.request.use(
    function(config) {
        let user = getUser();
        if (user) {
            let auth = `${user.token}`;
            config!.headers!["Auth-Token"] = auth;
            config!.headers!["Auth-Id"] = user.userId;
        }
        return config;
    },
    function(error) {
      return Promise.reject(error);
    }
);

export default instance;