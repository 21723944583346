import React, { useCallback, useState } from 'react';
import './styles.scss';

const IMAGE_COUNT = 35;

const Gallery = () => {
    const [showModal, setShowModal] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const onImageClick = useCallback((index: number) => {
        setShowModal(true);
        setImageIndex(index);
        document.querySelector('body')?.classList.add('modal-open');
    }, []);

    const onNavLeftClick = useCallback(() => {
        let newIndex = imageIndex - 1;
        newIndex = newIndex < 1? 1 : newIndex;
        setImageIndex(newIndex);
    }, [imageIndex]);

    const onNavRightClick = useCallback(() => {
        let newIndex = imageIndex + 1;
        newIndex = newIndex > IMAGE_COUNT? IMAGE_COUNT : newIndex;
        setImageIndex(newIndex);
    }, [imageIndex]);

    const onCloseButtonClick = useCallback(() => {
        setShowModal(false);
        document.querySelector('body')?.classList.remove('modal-open');
    }, []);

    return (
        <div className='gallery-page'>
            <span>Gallery</span>
            <div className="image-container">
                { Images({ onImageClick }) }
            </div>
            <div className={`${showModal? '' : 'd-none'} image-modal`}>
                <div className="image-container">
                    <button className='close-button' onClick={onCloseButtonClick}><i className="fa fa-times fa-2x"></i></button>
                    <img src={`/Media/Gallery/${imageIndex}.jpg`} alt="gallery" />
                    <button className="nav left" onClick={onNavLeftClick}>
                        <i className="fa fa-2x fa-chevron-left"></i>
                    </button>
                    <button className="nav right" onClick={onNavRightClick}>
                        <i className="fa fa-2x fa-chevron-right"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}

const Images = ({onImageClick}: {onImageClick: (index: number) => void}) => {
    let imgs: JSX.Element[] = [];

    // IMAGE_COUNT pics, all named by number
    for(let i = 1; i <= IMAGE_COUNT; i++) {
        imgs.push(
            <button key={`property_image-${i}`} onClick={() => onImageClick(i) }>
                <img src={`/Media/Gallery/250_${i}.jpg`} alt="property_image"></img>
            </button>
        )
    }

    return imgs;
}

export default Gallery;